import SingleDataStoreProvider from "wes_shell_app/single-data-store-provider";
import { environment } from "environment";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { TWarehouseMapDataTableRow } from "wes_shell_app/warehouse-map-utils";

export class WarehouseMapRequestProvider extends SingleDataStoreProvider<TWarehouseMapDataTableRow> {
  protected serviceApiUrl = environment.serviceApi;
  store = new SharedContextStore();
  currentStationId = this.store.appContext.currentStationId;

  get endpointUrl(): string {
    return `${this.url}${this.currentStationId}`;
  }

  get url(): string {
    return `ui/map/aisle`;
  }

  swapAisleUrl(aisleId: number): string {
    return `${this.url}/${aisleId}/swap`;
  }
}

import { TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import DataTable from "wes_shell_app/data-table";
import { useItemsViewStore } from "../hooks/use-items-store";
import { ItemsViewStore } from "../stores/items-view-store";
import { IClientItemsModel } from "models/client-models";
import { columns } from "../utils/table-items-columns";

export const ItemsDataTable = observer(() => {
  const store = useItemsViewStore();
  return (
    <DataTable
      page={store.page}
      isLoading={!store.isLoaded}
      onPageChange={store.setPage}
      activeSortProperty={store.sortBy}
      onSortChange={store.setSort}
      onDirectionChange={store.setDirection}
      direction={store.direction}
      pageCount={store.totalCount}
      rows={store.items}
      pageSize={store.pageSize}
      onPageSizeChange={store.setPageSize}
      columns={columns}
      rowTemplate={(item) => (
        <RowTemplate key={item.id} item={item} store={store} />
      )}
    />
  );
});

type IRowTemplateProps = {
  item: IClientItemsModel;
  store: ItemsViewStore;
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const { store, item } = props;

  return (
    <>
      <TableRow>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.line}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.pickOrder}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}>
          {item.pickAddress}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.area}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.aisle}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.bay}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.level}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.cell}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.checkDigit}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}>
          {item.status}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}>
          {item.itemId}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}>
          {item.itemLogistics}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}>
          {item.itemName}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}>
          {item.weightFlag}
        </TableCell>
        <TableCell
          align="right"
          sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}
        >
          {item.avgWeight}
        </TableCell>
        <TableCell
          align="right"
          sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}
        >
          {item.minWeightLimit}
        </TableCell>
        <TableCell
          align="right"
          sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}
        >
          {item.maxWeightLimit}
        </TableCell>
        <TableCell
          align="right"
          sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}
        >
          {item.pickQtyPc}
        </TableCell>
        <TableCell
          align="right"
          sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}
        >
          {item.pickQtyPu}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}>
          {item.pickUnit}
        </TableCell>
        <TableCell
          align="right"
          sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}
        >
          {item.pickedQtyPc}
        </TableCell>
        <TableCell
          align="right"
          sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}
        >
          {item.availableQtyPc}
        </TableCell>
        <TableCell
          align="right"
          sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}
        >
          {item.pcInPu}
        </TableCell>
        <TableCell
          align="right"
          sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}
        >
          {item.pcInSubcart}
        </TableCell>
        <TableCell
          align="right"
          sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}
        >
          {item.subcartInCart}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}>
          {item.autoPickId}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", paddingLeft: "0px" }}>
          {item.comment}
        </TableCell>
      </TableRow>
    </>
  );
});

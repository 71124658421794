export const basePath = "/voice-picking";
export enum RoutePaths {
  home = "",
  printers = "/printers",
  parameters = "/parameters",
  racks = "/racks",
  terminals = "/terminals",
  orders = "/orders",
  warehouseMap = "/warehouse-map",
}

export function menu() {
  return {
    groups: [
      {
        groupTitle: "menu",
        items: [
          {
            title: "dashboard",
            link: basePath + RoutePaths.home,
            icon: "Dashboard",
          },
        ],
      },
      {
        groupTitle: "settingsGroup",
        items: [
          {
            title: "printers",
            link: basePath + RoutePaths.printers,
            icon: "Print",
          },
          {
            title: "parameters",
            link: basePath + RoutePaths.parameters,
            icon: "EditNote",
          },
          {
            title: "racks",
            link: basePath + RoutePaths.racks,
            icon: "CalendarViewMonth",
          },
          {
            title: "warehouseMap",
            link: basePath + RoutePaths.warehouseMap,
            icon: "Map",
          },
        ],
      },
      {
        groupTitle: "operations",
        items: [
          {
            title: "terminals",
            link: basePath + RoutePaths.terminals,
            icon: "PhoneAndroid",
          },
          {
            title: "orders",
            link: basePath + RoutePaths.orders,
            icon: "ReceiptLong",
          },
        ],
      },
    ],
  };
}

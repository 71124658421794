import { IDataTableHeaderCellProps } from "wes_shell_app/data-table";

export const columns: IDataTableHeaderCellProps[] = [
  { label: "priority" },
  { label: "created" /*sortKey: "created"*/ },
  { label: "batchId" /*sortKey: "batchId"*/ },
  { label: "lot" /*sortKey: "lot"*/ },
  { label: "zone" },
  { label: "erpZone", info: "zoneErpInfo" },
  { label: "status" /*sortKey: "status" */ },
  { label: "supportCount", info: "supportCountInfo", justify: "end" },
  { label: "supportCode", info: "supportCodeInfo" },
  { label: "customer" },
  { label: "expDockAddress", info: "expDockAddressInfo" },
  { label: "checkDigit", info: "checkDigitInfo" },
  { label: "lotType", info: "lotTypeInfo" },
  { label: "qtySum", justify: "end" },
  {
    label: "lotOrder",
    /* sortKey: "lotOrder",*/ info: "lotOrderInfo",
    justify: "end",
  },
  { label: "itemsCount", info: "itemsCountInfo", justify: "end" },
  { label: "preparedTo" },
  { label: "lotStartDate" },
  { label: "lotEndDate" },
];

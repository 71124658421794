import React from "react";
import { Routes as ReactRoutes, Route } from "react-router-dom";
import { useRouting } from "wes_shell_app/use-routing";
import DashboardView from "features/dashboard/views/dashboard-view";
import PrintersView from "features/printers/views/printers-view";
import ParametersView from "features/parameters/views/parameters-view";
import { useNavigationStore } from "wes_shell_app/use-navigation-store";
import { observer } from "mobx-react";
import { menu, RoutePaths, basePath } from "./menu";
import RackView from "features/rack/views/rack-view";
import TerminalView from "features/terminal/views/terminal-view";
import OrdersView from "features/orders/views/orders-view";
import WarehouseMapView from "features/warehouse-map/views/warehouse-map-view";

export const Routes = observer(() => {
  const navStore = useNavigationStore();
  const routerStore = useRouting();

  React.useEffect(() => {
    routerStore.addPaths([...Object.values(RoutePaths)], basePath);
    navStore.setItems(menu().groups);
  }, []);

  return (
    <ReactRoutes>
      <Route path={RoutePaths.home} element={<DashboardView />} />
      <Route path={RoutePaths.printers} element={<PrintersView />} />
      <Route path={RoutePaths.parameters} element={<ParametersView />} />
      <Route path={RoutePaths.racks} element={<RackView />} />
      <Route path={RoutePaths.terminals} element={<TerminalView />} />
      <Route path={RoutePaths.orders} element={<OrdersView />} />
      <Route path={RoutePaths.warehouseMap} element={<WarehouseMapView />} />
    </ReactRoutes>
  );
});

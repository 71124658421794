import { IDataTableHeaderCellProps } from "wes_shell_app/data-table";

export const columns: IDataTableHeaderCellProps[] = [
  { label: "line", sortKey: "line" },
  { label: "pickOrder", info: "pickOrderInfo" },
  { label: "pickAddress", info: "pickAddressInfo" },
  { label: "area" },
  { label: "aisle" },
  { label: "bay" },
  { label: "level" },
  { label: "cell" },
  { label: "checkDigit", info: "checkDigitInfo" },
  { label: "status" },
  { label: "itemId", info: "itemIdInfo" },
  { label: "itemLogistics", info: "itemLogisticsInfo" },
  { label: "itemName" },
  { label: "weightFlag", info: "weightFlagInfo" },
  { label: "avgWeight", info: "avgWeightInfo", justify: "end" },
  { label: "minWeightLimit", info: "minWeightLimitInfo", justify: "end" },
  { label: "maxWeightLimit", info: "maxWeightLimitInfo", justify: "end" },
  { label: "pickQtyPc", info: "pickQtyPcInfo", justify: "end" },
  { label: "pickQtyPu", info: "pickQtyPuInfo", justify: "end" },
  { label: "pickUnit", info: "pickUnitInfo" },
  { label: "pickedQtyPc", info: "pickedQtyPcInfo", justify: "end" },
  { label: "availableQtyPc", info: "availableQtyPcInfo", justify: "end" },
  { label: "pcInPu", info: "pcInPuInfo", justify: "end" },
  { label: "pcInSubcart", info: "pcInSubcartInfo", justify: "end" },
  { label: "subcartInCart", info: "subcartInCartInfo", justify: "end" },
  { label: "autoPickId", info: "autoPickIdInfo" },
  { label: "comment" },
];

import SingleDataStoreProvider from "wes_shell_app/single-data-store-provider";
import { environment } from "environment";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { TWarehouseMapRackModel } from "wes_shell_app/warehouse-map-utils";

export class WarehouseMapRowRequestProvider extends SingleDataStoreProvider<TWarehouseMapRackModel> {
  protected serviceApiUrl = environment.serviceApi;
  store = new SharedContextStore();
  currentStationId = this.store.appContext.currentStationId;

  get endpointUrl(): string {
    return `${this.url}`;
  }

  get url(): string {
    return `ui/map/row`;
  }
}

import SingleDataStoreProvider from "wes_shell_app/single-data-store-provider";
import { environment } from "environment";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { TWarehouseMapRackModel } from "wes_shell_app/warehouse-map-utils";
import { makeObservable, observable } from "mobx";

export class WarehouseMapRackRequestProvider extends SingleDataStoreProvider<TWarehouseMapRackModel> {
  protected serviceApiUrl = environment.serviceApi;
  store = new SharedContextStore();
  currentStationId = this.store.appContext.currentStationId;

  constructor() {
    super();
    makeObservable(this, {
      aisleId: observable,
    });
  }

  aisleId: number = null;

  setAisleId(value: number) {
    this.aisleId = value;
  }

  get url(): string {
    return `ui/map/rack`;
  }

  get endpointUrl(): string {
    return `ui/map/aisle/${this.aisleId}/rack`;
  }
}

import { Box, TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import DataTable from "wes_shell_app/data-table";
import { useOrdersViewStore } from "../hooks/use-orders-store";
import { IClientOrdersModel } from "models/client-models";
import { OrdersViewStore } from "../stores/orders-view-store";
import { useItemsViewStore } from "../hooks/use-items-store";
import { ItemsViewStore } from "../stores/items-view-store";
import { columns } from "../utils/table-orders-columns";
import Button from "wes_shell_app/button";
import { List } from "@mui/icons-material";
import formatters from "wes_shell_app/localization-utils";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const OrdersDataTable = observer(() => {
  const store = useOrdersViewStore();
  const itemsStore = useItemsViewStore();

  const handleSelectOrderId = (item: IClientOrdersModel) => {
    itemsStore.selectItem = item;
    itemsStore.setSelectedOrderId(item.id);
    itemsStore.openDrawer();
  };

  const priorityUp = (item: IClientOrdersModel) => {
    //TODO store.priorityUpdateAPI(item.id, item.priority + 1);
  };
  const priorityDown = (item: IClientOrdersModel) => {
    //TODO store.priorityUpdateAPI(item.id, item.priority - 1);
  };

  return (
    <DataTable
      page={store.page}
      isLoading={!store.isLoaded}
      onPageChange={store.setPage}
      activeSortProperty={store.sortBy}
      onSortChange={store.setSort}
      onDirectionChange={store.setDirection}
      direction={store.direction}
      pageCount={store.totalCount}
      rows={store.items}
      pageSize={store.pageSize}
      onPageSizeChange={store.setPageSize}
      columns={columns}
      rowTemplate={(item) => (
        <RowTemplate
          key={item.id}
          item={item}
          store={store}
          itemsStore={itemsStore}
          onSelect={() => handleSelectOrderId(item)}
          onPriorityUp={() => priorityUp(item)}
          onPriorityDown={() => priorityDown(item)}
        />
      )}
    />
  );
});

type IRowTemplateProps = {
  item: IClientOrdersModel;
  store: OrdersViewStore;
  itemsStore: ItemsViewStore;
  onSelect: () => void;
  onPriorityUp: () => void;
  onPriorityDown: () => void;
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const { onSelect, item, onPriorityUp, onPriorityDown } = props;

  return (
    <>
      <TableRow>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.status}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {formatters.formatDateTime(item.issued as any)}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.batchId}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.lot}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {`(${item.workplace?.id}) ${item.workplace?.name}`}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.erpZone}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          <Box display="flex" flexDirection="column">
            <Button
              className="p-1 w-5 max-h-5"
              onClick={onPriorityUp}
              variant="icon"
              icon={<ArrowDropUpIcon color="primary" />}
            />
            <Button
              className="p-1 w-5 max-h-5"
              onClick={onPriorityDown}
              variant="icon"
              icon={<ArrowDropDownIcon color="primary" />}
            />
          </Box>
          {item.priority}
        </TableCell>
        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
          {item.supportCount}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.supportCode}</TableCell>
        <TableCell
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {item.customer}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {item.expDockAddress}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.checkDigit}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.lotType}</TableCell>
        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
          {item.qtySum}
        </TableCell>
        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
          {item.lotOrder}
        </TableCell>
        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
          {item.itemsCount}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.lotStartDate}</TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>{item.lotEndDate}</TableCell>
        <TableCell>
          <Button
            onClick={onSelect}
            variant="icon"
            icon={<List color="primary" />}
          />
        </TableCell>
      </TableRow>
    </>
  );
});

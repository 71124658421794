import { observer } from "mobx-react";
import BaseView from "wes_shell_app/base-view";
import { useWarehouseMapStore } from "../hooks/use-warehouse-map-store";
import { WareHouseMap } from "wes_shell_app/warehouse-map";
import { WarehouseMapRequestProvider } from "../stores/data/warehouse-map-request-provider";

const WarehouseMapView = observer(() => {
  const store = useWarehouseMapStore();

  return (
    <BaseView>
      <WareHouseMap
        page={store.page}
        isLoading={!store.isLoaded}
        onPageChange={store.setPage}
        activeSortProperty={store.sortBy}
        onSortChange={store.setSort}
        onDirectionChange={store.setDirection}
        direction={store.direction}
        pageCount={store.totalCount}
        rows={store.items}
        pageSize={store.pageSize}
        onPageSizeChange={store.setPageSize}
        isAdmin={store.isAdmin}
        forceReload={store.forceReload}
        setSelectAisle={store.setSelectAisleId}
        resetSelectedAisleId={store.resetSelectedAisleId}
        aisleProvider={new WarehouseMapRequestProvider()}
        rackProvider={store.getRackRequestProvider()}
        racks={store.racks}
        deleteRack={store.deleteRack}
        deleteAisle={store.deleteAisle}
        editRack={store.editRack}
        changePosition={store.changeRackPosition}
        editAisle={store.editAisle}
        editRowTitle={store.editRowTitle}
      />
    </BaseView>
  );
});

export default WarehouseMapView;
